import React from 'react'
import PropTypes from 'prop-types'
import { createLocaleTextGetter } from '@helpers/createLocaleTextGetter' // Or wherever you stashed it

export default Component => {
  const Localize = ({ data, pageContext, ...rest }) => {
    const getLocalizedContent = createLocaleTextGetter(pageContext.locale);

    return (
      <Component
        {...rest}
        data={getLocalizedContent(data)}
        pageContext={getLocalizedContent(pageContext)}
      />
    )
  }

  Localize.propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.shape({
      locale: PropTypes.string,
    }),
  }

  return Localize
}
