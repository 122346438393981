import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import 'intersection-observer'
import cn from 'classnames'

const InViewport = React.forwardRef(({
  activeClass,
  trackLeave,
  threshold,
  className,
  children,
  ...rest
}, ref) => {
  const [active, setActive] = useState(false)
  const target = ref ? ref : useRef(null)
  const observer = useRef(null)

  const onObserverChange = entries => {
    const intersectingEntry = entries.find(entry => entry.isIntersecting);
    if (intersectingEntry && intersectingEntry.boundingClientRect.height === 0 && intersectingEntry.boundingClientRect.width === 0) {
      return; // Does not count if Target DOM hasn't been rendered
    }
    trackLeave ? setActive(!!intersectingEntry) : setActive(active || !!intersectingEntry)
  }

  useEffect(() => {
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(onObserverChange, { threshold: threshold })
    if (target.current) observer.current.observe(target.current)
    return () => observer.current.disconnect()
  })

  return (
    <div
      className={cn(
        className,
        'in-viewport--init',
        {[activeClass]: active}
      )}
      ref={target}
      {...rest}
    >
      {children}
    </div>
  )
})

InViewport.propTypes = {
  activeClass: PropTypes.string,
  trackLeave: PropTypes.bool,
  threshold: PropTypes.number
}

InViewport.defaultProps = {
  activeClass: 'in-viewport--active',
  trackLeave: false,
  threshold: 0,
}

export default InViewport
